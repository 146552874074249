.periodicity-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px 15px 0 15px;
}

.periodicity-label-container{
    width: 20%
}

.periodicity-body-container {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.periodicity-day-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.periodicity-ending-container {
    display: flex;
    flex-direction: column;
}

.periodicity-snippet-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.checkbox-container {
    margin-right: 5px;
}

.radio-container {
    padding: 2px 0;
    label {
        margin: 0;
        padding-left: 5px;
    }
}