.file-div{
  margin-top: 30px;
}
.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  cursor: inherit;
  display: block;
}
.output-img{
  max-height: 80px;
  max-width: 250px;
}
.photo-container{
  margin-bottom: 30px;
}
.output-container{
  margin-top: 10px;
  border: 1px lightgray solid;
  padding: 5px;

  #output_info{
    color: cornflowerblue;
    padding: 0 5px;
  }
}