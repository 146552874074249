// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// If you want to add something do it here
@import "Dashboard";
@import "Login";
@import "Manufacturer";
@import "Item";
@import "Paginator";
@import "CreateOrder";
@import "PurchasePlan";

// ie fixes
@import "ie-fix";

// HTML =================================================

body{
  background-color: white;
}
.table th, .table td{
   vertical-align: middle;
 }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}

// Bootstrap ============================================

.btn-default{
  border: 1px solid;
}
.row{
  margin-bottom: 10px;
}

// Module CSS ===========================================

.main {
  .select-search-box{
    width: 100%;
    margin: 0;
    border: 1px solid #e4e7ea;
    border-radius: 0.25rem;
    ul{
      padding-left: 0;
      margin-bottom: 0;
    }
    .select-search-box__search{
      height: 33px;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      color: #5c6873;
      padding: 0 15px;
      box-shadow: none;
    }
    .select-search-box__select{
      top: 34px;
      border-radius: 0 0 4px 4px;
      max-height: 300px !important;
    }
    .select-search-box__option{
      font-size: 15px;
      padding: 10px 20px;
    }
  }
  .select-search-box::after{
    line-height: 35px;
  }
}

// Main CSS =============================================

.text-align-center{
  text-align: center;
}

.border-none > td{
  border: 0 !important;
}

.no-margin{
  margin: 0 0 0 0;
}

.left-margin{
  margin: 0 0 0 5px;
}

.hidden{
  display: none !important;
}

.spinner{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;
  z-index: 1001;
}

.full-width{
  width: 100%;
}

.left-form-collumn{
  width: 50%;
  align-items: flex-start;
}

.signature{
  display: inline-block;
  width: 125px;
  height: 50px;
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
}

.redal-logo{
  background-image: url("assets/img/brand/redal/logo.png");
}

.berner-logo{
  background-image: url("assets/img/brand/berner/logo.svg");
}

.width-200{
  width: 200px;
}
.action-button-td{
  max-width: 200px;
  min-width: 110px;
  text-align: center;

  a, .clear-search{
    margin-right: 5px;
  }

  a.price-balance{
    margin-left: 5px;
    margin-right: 0;
  }
}

.modal-content-container h2.log-title{
  margin-bottom: 30px;
}

.card-header{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.create-link{
  margin-left: 5px;
}

.edit-parent{
  width: 100%;
  display: flex;
  flex-direction: column;

  .action-buttons{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
  }
  form{
    display: flex;
    flex-direction: column;
  }
  form label{
    display: flex;
    flex-direction: column;
  }
  .footer-actions{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .right-flex{
    justify-content: flex-end;
  }
}

.footer-action-center{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 40px;
  padding-left: 15px;
  margin-bottom: 20px;

  button{
    margin: 0 10px;
  }
}

.edit-content{
  padding: 20px 20px;
}

.form-titles{
  margin-top: 30px;
  margin-bottom: 30px;
}

.one-row{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}

.one-column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: flex-start;
}

.flex{
  display: flex;
}

.flex-row{
  display: flex;
  flex-direction: row;
}

.flex-column{
  display: flex;
  flex-direction: column;
}

.center{
  justify-content: center;
}

.align-items-center{
  align-items: center;
}

.start{
  justify-content: flex-start;
}

.end{
  justify-content: flex-end;
}

.header-edit{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.quantity-input {
  width: 70%;
  margin-right: 10%;
}

.button-whitespace {
  margin: 0 5px;
}

.preview{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.2);

  .preview-child{
    width: 50%;
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    position: relative;

    .info{
      b{
        font-size: 16px;
      }
      span{
        margin-left: 10px;
      }
    }

    .close-preview{
      position: absolute;
      right: 20px;
    }
  }
}

.modal-content-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  h2 {
    text-align: center;
    margin-bottom: 15%;
  }
}

.center-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.filter-select{
  max-width: 200px;
}

.tabs{
  overflow: hidden;

  button{
    background-color: rgba(204, 204, 204, 1);
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
  }

  button.active {
    background-color: rgba(242, 242, 242, 1);
  }
}

.item-tab-content{
  display: none;
  background-color: rgba(242, 242, 242, 1);
  padding: 20px;
}

.suggestion-container{
  position: absolute;
  border: 1px solid #e4e7ea;
  background-color: #fff;
  z-index: 2;
  width: calc(100% - 30px);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  ul{
    margin: 0;
    padding: 0;
    list-style-type: none;

    li{
      cursor: pointer;
      padding: 10px 20px;
    }

    li:hover{
      background-color: lightgray;
    }
  }
}

// Cashier ================================
.kasa-main{
  display: flex;
  flex-direction: column;
  padding: 20px;
  .event-selector{
    width: 300px;
    h3{
      margin-top: 10px;
      margin-left: 5px;
    }
  }
  .event-selector.bigger-width{
    width: 800px;
  }
}
.check-in{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  .check-in-sub{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .check-in-sub-sub{
      align-items: start;
    }
  }
  .check-in-buttons{
    display: flex;
    flex-direction: row;
    input{
      width: auto;
    }
    button{
      margin-left: 10px;
    }
  }
  .check-in-result{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    border: 1px solid black;
    div{
      padding: 20px 30px 20px 30px;
      font-size: 17px;
      text-align: center;
    }
    div:first-of-type{
      padding: 20px 30px 0 30px;
    }
    div.success, div.reject{
      padding: 20px 30px;
    }
    .success{
      background-color: greenyellow;
    }
    .reject{
      background-color: red;
    }
  }
}
.items-table{
  margin-top: 40px;
}
.sell-buttons{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.edit-content .react-datepicker-popper{
  z-index: 3;
}
